import { Case, makeEnum } from '@technicated/ts-enums'
import { HttpRequest } from '@shared-models'

class SessionProto {
  print(this: Session, request: HttpRequest): HttpRequest {
    switch (this.case) {
      case 'comuniUtente':
        return request.appendingPathComponent('/items/account')
          .withQueryParam('filter[user][_eq]', this.p.userId)
          .withQueryParam('fields[]', 'comuni.id_comune.id,comuni.id_comune.nome,comuni.id_comune.pv.sigla')

      case 'fetchActiveUser':
        return request.appendingPathComponent('users/me')
          .withQueryParam('fields[]', 'id,first_name,last_name,role.name')

      case 'logoutUser':
        return request.appendingPathComponent('auth/logout')
          .withMethod('POST')
          .withBody({ mode: 'session' })
    }
  }
}
export type Session = SessionProto & (
  | Case<'comuniUtente', { userId: string }>
  | Case<'fetchActiveUser'>
  | Case<'logoutUser'>
  )

export const Session = makeEnum<Session>({ proto: SessionProto })
