<it-header
  slimTitle="Regione Umbria"
  slimTitleLink="https://www.regione.umbria.it"
  [loginStyle]="'none'"
  [light]="false"
  [showSearch]="false"
  [sticky]="true"
  [megamenu]="false">

  <ng-container slimRightZone>
    <div class="me-3 bg-warning px-3">
      @if (!environment.production) {
        <p class="text-white mt-2">
          SVILUPPO
        </p>
      }
    </div>

    <div class="me-3">
      <p class="text-white mt-2">
        @if (activeUser(); as user) {
          {{ user.name }} {{ user.surname }}
        } @else {
          Utente non trovato
        }
      </p>
    </div>

    <div class="primary-bg h-100 p-2 text-white login-button" (click)="logout()">
      Esci dall'applicazione
    </div>
  </ng-container>

  <ng-container brand>
    <a>
      <img class="img-fluid" ngSrc="../assets/SIRSE_logo.png" alt="logo SIRSE" height="70" width="70">

      <div class="it-brand-text ion-margin-start">
        <div class="it-brand-title">SIRSE</div>

        <div class="it-brand-tagline d-none d-md-block">Servizio Informativo Regionale dei Servizi Socio-Educativi</div>
      </div>
    </a>
  </ng-container>

  <ng-container navItems>
    <it-navbar-item (click)="navigationIsLoading.set(true)">
        <a class="nav-link {{ activeRoute === 'licenses' ? 'active' : '' }}" routerLink="/licenses" aria-current="page">
          <span>Autorizzazioni</span>
        </a>
    </it-navbar-item>

    <it-navbar-item (click)="navigationIsLoading.set(true)">
      <a class="nav-link {{ activeRoute === 'questionnaires' ? 'active' : '' }}" routerLink="/questionnaires" aria-current="page">
        <span>Schede</span>
      </a>
    </it-navbar-item>

    @if (activeUserCanUseAdministrationNavbarItem) {
      <it-navbar-item>
        <it-dropdown mode="nav">
        <span button>
          Amministrazione
        </span>

          <ng-container list>
            <it-dropdown-item (click)="goToUsersPage()">Gestione utenti</it-dropdown-item>

            @if (activeUserCanHandleSurveysAndProgrammazione) {
              <it-dropdown-item (click)="goToSurveysPage()">Gestione rilevazioni</it-dropdown-item>

              <it-dropdown-item (click)="goToProgrammazionePage()">Programmazione</it-dropdown-item>
            }
          </ng-container>
        </it-dropdown>
      </it-navbar-item>
    }

    @if (activeUserCanUseFondiNavbarItem) {
      <it-navbar-item>
        <it-dropdown mode="nav">
        <span button>
          Fondi
        </span>

          <ng-container list>
            <it-dropdown-item (click)="goToProgrammazioneComunePage()">Programmazione</it-dropdown-item>
          </ng-container>
        </it-dropdown>
      </it-navbar-item>
    }
  </ng-container>
</it-header>

@if(isLoading()) {
  <it-progress-bar color="primary" [value]="0" indeterminate="true"/>
}
