import { NgOptimizedImage } from '@angular/common'
import { Component, computed, inject, input, signal, WritableSignal } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router, RouterLink } from '@angular/router'
import {
  ItDropdownComponent,
  ItDropdownItemComponent,
  ItHeaderComponent,
  ItNavBarItemComponent, ItNotificationService, ItProgressBarComponent,
} from 'design-angular-kit'
import { catchError, exhaustMap, of, Subject, tap } from 'rxjs'
import { LogoutResponse } from '../authentication-client/authentication'
import { AuthenticationClient } from '../authentication-client/authentication-client'
import { environment } from '../environments/environment'
import { SessionClient } from '../session-client/session-client'
import { ApiClient } from '@api-client'

@Component({
  selector: 'sir-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    ItHeaderComponent,
    ItNavBarItemComponent,
    RouterLink,
    ItDropdownItemComponent,
    ItDropdownComponent,
    NgOptimizedImage,
    ItProgressBarComponent,
  ],
})
export class HeaderComponent {
  protected readonly environment = environment

  isLoading = computed(() => this.pageIsLoading()
    || this.navigationIsLoading()
    || this.logoutRequestLoading(),
  )
  pageIsLoading = input<boolean>(false, { alias: 'isLoading' })
  navigationIsLoading: WritableSignal<boolean> = signal<boolean>(false)
  logoutRequestLoading: WritableSignal<boolean> = signal<boolean>(false)

  private readonly notificationService = inject(ItNotificationService)
  private router = inject(Router)

  get activeRoute(): string {
    return this.router.url.split('/')[1]
  }

  apiClient = inject(ApiClient)
  authenticationClient = inject(AuthenticationClient)
  sessionClient = inject(SessionClient)

  logoutRequest$ = new Subject<void>()
  activeUser = this.sessionClient.activeUser

  get activeUserCanHandleSurveysAndProgrammazione(): boolean {
    const activeUser = this.activeUser()

    if (activeUser) {
      switch (activeUser.roleName) {
        case 'Administrator':
          return true
        case 'Comune':
          return false
        case 'Titolare':
          return false
        case 'Regione':
          return true
      }
    }

    return false
  }

  get activeUserCanUseFondiNavbarItem(): boolean {
    const activeUser = this.activeUser()

    if (activeUser) {
      switch (activeUser.roleName) {
        case 'Administrator':
          return true
        case 'Comune':
          return true
        case 'Titolare':
        case 'Regione':
          return false
      }
    }

    return false
  }

  get activeUserCanUseAdministrationNavbarItem() {
    const activeUser = this.activeUser()

    if (activeUser) {
      switch (activeUser.roleName) {
        case 'Administrator':
          return true
        case 'Comune':
          return true
        case 'Titolare':
          return false
        case 'Regione':
          return true
      }
    }

    return false
  }

  constructor() {
    this.logoutRequest$.pipe(
      tap(() => this.logoutRequestLoading.set(true)),
      exhaustMap(() =>
        this.authenticationClient.logoutUser().pipe(
          catchError((err) => of<LogoutResponse>({ case: 'error', p: { message: `${err}` } })),
        ),
      ),
      tap((response) => {
        this.logoutRequestLoading.set(false)

        switch (response.case) {
          case 'error':
            this.notificationService.error(
              'Errore',
              'Il logout non è stato eseguito correttamente, si prega di riprovare.',
            )
            break
          case 'success':
            this.sessionClient.activeUser.set(null)
            this.router.navigate(['/login']).then(() => this.logoutRequestLoading.set(false))
            break
        }
      }),
      takeUntilDestroyed(),
    ).subscribe()
  }

  goToProgrammazionePage() {
    this.navigationIsLoading.set(true)
    this.router.navigate(['administration/programmazione']).then(() => this.navigationIsLoading.set(false))
  }

  goToProgrammazioneComunePage() {
    this.navigationIsLoading.set(true)
    this.router.navigate(['fondi/programmazione']).then(() => this.navigationIsLoading.set(false))
  }

  goToSurveysPage() {
    this.navigationIsLoading.set(true)
    this.router.navigate(['administration/surveys']).then(() => this.navigationIsLoading.set(false))
  }

  goToUsersPage() {
    this.navigationIsLoading.set(true)
    this.router.navigate(['administration/users']).then(() => this.navigationIsLoading.set(false))
  }

  logout() {
    this.logoutRequest$.next()
  }
}
