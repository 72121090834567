import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { APP_INITIALIZER, enableProdMode } from '@angular/core'
import { bootstrapApplication } from '@angular/platform-browser'
import {
  provideRouter,
  Router, RouteReuseStrategy,
  withNavigationErrorHandler,
} from '@angular/router'
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone'
import { ItNotificationService, provideDesignAngularKit } from 'design-angular-kit'
import { catchError, EMPTY, Observable, tap } from 'rxjs'
import { AppComponent } from './app/app.component'
import { routes } from './app/app.routes'
import { AuthenticationClient } from './authentication-client/authentication-client'
import { AuthenticationClientLive } from './authentication-client/live'
import { environment } from './environments/environment'
import { SessionClient } from './session-client/session-client'
import { StorageClientLive } from './storage-client/live'
import { StorageClient } from './storage-client/storage-client'
import { ApiValidatorsService } from './utils/api-validators.service'
import { AuthInterceptor } from './utils/auth-interceptor/auth-interceptor'
import { DateService } from './utils/date-service/date.service'
import { RealTimeDateService } from './utils/date-service/real-time'
import { LogServiceLive } from './utils/log-service/live'
import { LogService } from './utils/log-service/log-service'
import { NavigationErrorHandler } from './utils/navigation-error-handler'
import { ApiClient, ApiClientLive } from '@api-client'

if (environment.production) {
  enableProdMode()
}

export function initializeApp(authenticationClient: AuthenticationClient, sessionClient: SessionClient, router: Router, toast: ItNotificationService): () => Observable<any> {
  return () => authenticationClient.fetchActiveUser().pipe(
    tap((user) => {
      if (user) {
        sessionClient.activeUser.set(user)
      } else {
        router.navigate(['login'])
      }
    }),
    catchError(() => {
      router.navigate(['login']).then(() => toast.error(
        'Errore',
        'È avvenuto un errore durante l\'autenticazione, si prega di riprovare; ' +
        'se il problema persiste, contattare l\'assistenza',
      ))
      return EMPTY
    }),
  )
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps:[
        AuthenticationClient,
        SessionClient,
        Router,
        ItNotificationService,
      ],
    },
    { provide: ApiClient, useClass: ApiClientLive },
    { provide: AuthenticationClient, useClass: AuthenticationClientLive },
    { provide: DateService, useClass: RealTimeDateService },
    { provide: LogService, useClass: LogServiceLive },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: StorageClient, useClass: StorageClientLive },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ApiValidatorsService,
    SessionClient,
    provideDesignAngularKit(),
    provideIonicAngular(),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    provideRouter(routes, withNavigationErrorHandler((e) => NavigationErrorHandler(e))),
  ],
})
